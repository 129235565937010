<template>
  <div class="charts">
    <div
      v-if="isICE !== 'ice'"
      class="SOC-chart"
    >
      <apex-chart
        :loading="isLoading"
        name="trips"
        title="State Of Charge"
        :labels="chartTimeStamp"
        :value="seriesSOC"
        :xaxis-text="$t('dateTime')"
        date-format="hh:mm:ss A"
        :yaxis="{ min: 0, max: 100 }"
        unit="%"
      />
    </div>
    <div class="Speed-chart">
      <apex-chart
        :loading="isLoading"
        name="trips"
        :title="$t('speed')"
        :labels="chartTimeStamp"
        :value="seriesSpeed"
        :xaxis-text="$t('dateTime')"
        date-format="hh:mm:ss A"
        :yaxis="{ min: 0, max: getMax(seriesSpeed) }"
      />
      <!-- unit="(KM/Hr)" -->
      <!-- <apex-chart
        :loading="isLoading"
        name="trips"
        :title="'Speed'"
        :labels="chartTimeStamp"
        :value="seriesSpeed"
        :xaxis-text="'Timestamp'"
        unit="(KM/Hr)"
      /> -->
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {
    ApexChart: () => import("@/components/sections/ApexAreaChart.vue"),
  },
  data() {
    return {
      loopInterval: 10,
      //
      chartTimeStamp: [],
      seriesSOC: [],
      seriesSpeed: [],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "vehicleTrips/getIsLoading",
      list: "vehicleTrips/getTripDashboardChart",
    }),
    isICE() {
      return this.$route?.query?.vehicle_tech;
    },
  },
  async mounted() {
    await this.fetchData();
  },
  beforeDestroy() {
    this.$store.commit("vehicleTrips/SET_TRIP_CHART", []);
  },
  methods: {
    async fetchData() {
      const params = {};
      params.trip_interval_id = this.$route.params?.id;
      params.imei = this.$route?.query?.imei;
      await this.$store
        .dispatch("vehicleTrips/tripsDashboardChart", params)
        .then(() => {
          this.setChartData(this.list);
        });
    },

    setChartData(data) {
      const timeLabel = [];
      const soc = [];
      const speed = [];

      data.forEach((el, i) => {
        // according to Loop interval which start from 0 index
        if (i % this.loopInterval == 0) {
          timeLabel.push(el.date_time_gmt_05);
          soc.push({ x: new Date(el.date_time_gmt_05), y: el.state_of_charge });
          speed.push({
            x: new Date(el.date_time_gmt_05),
            y: el?.position?.speed,
          });

          // timeLabel.push(el.date_time_gmt_05.split(" ")[1]);
          // soc.push(el.state_of_charge);
          // speed.push(el?.position?.speed);
        }
        // push Last Value if it not in Loop interval
        else if (i == data.length - 1) {
          timeLabel.push(el.date_time_gmt_05);
          soc.push({ x: new Date(el.date_time_gmt_05), y: el.state_of_charge });
          speed.push({
            x: new Date(el.date_time_gmt_05),
            y: el?.position?.speed,
          });
          // timeLabel.push(el.date_time_gmt_05.split(" ")[1]);
          // soc.push(el.state_of_charge);
          // speed.push(el?.position?.speed);
        }

        this.chartTimeStamp = timeLabel;
        this.seriesSOC = soc;
        this.seriesSpeed = speed;
      });
    },
    //
    getMax(val) {
      const arr = val.map((r) => parseInt(r.y));
      const sr = Math.max(...arr);
      return sr > 10 ? sr : 10;
    },
  },
};
</script>
<style lang="sass" scoped>
.charts
    display: flex
    gap:1rem
    margin-top: 1.5rem
    .SOC-chart , .Speed-chart
        flex: 1

@media only screen and (max-width: 960px)
    .charts
        flex-direction: column
</style>
